@use "../global" as *;

/*=================================
  common
=================================*/
body {
  @extend %font;
  position: relative;
  word-break: break-word;
}
.float-l {
  float: left;
}
.float-r {
  float: right;
}
.note {
  font-size: 1.5rem;
  display: block;
}
.is-sp-only {
  display: none!important;
}
.is-text-center {
  text-align: center;
}
.is-text-right {
  text-align: right;
}
.is-text-note {
  font-size: 1.2rem;
  padding-left: 1em;
  text-indent: -1em;
  display: block;
}
.inner-wrap {
  max-width: $base-width-in;
  margin: 0 auto;
}
.cf:after {
  content:" ";
  display:block;
  clear:both;
}
/*共通レイアウト*/
.layout--2col {
  max-width: calc(#{$base-width} + 2%);
  margin: 0 auto;
  padding: 60px 1% 90px;
  display: flex;
  align-items: flex-start;
  &__cont {
    width: calc(100% - (320px + vw(60)));
  }
  &.cont--r {
    flex-direction: row-reverse;
    .layout--2col__cont {
      margin-left: vw(60);
    }
  }
  &.cont--l {
    .layout--2col__cont {
      margin-right: vw(60);
    }
  }
}

@media screen and (max-width: 1023px) {
  .layout--2col {
    padding: per(40) per(17);;
    display: block;
    &__cont {
      width: 100%;
    }
    &.cont--r {
      .layout--2col__cont {
        margin: 0;
      }
    }
    &.cont--l {
      .layout--2col__cont {
        margin: 0;
      }
    }
  }
}

@include mq(sp) {
  /*=================================
    common
  =================================*/
  html,body {
    min-width: 320px;
  }
  .is-sp-only {
    display: inline-block!important;
  }
  .is-pc-only {
    display: none!important;
  }
  .note {
    font-size: 1.1rem;
  }
}
